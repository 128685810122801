import React from 'react'
import Layout from '../components/Layout'
// import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import Time from '../components/Time'
import 'react-vertical-timeline-component/style.min.css'
import '../fonts/layout.css'

const eventAgenda = ({ data, location }) => {
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const airTable = data.allAirtable.edges

    return (
        <Layout data={data} jsonData={jsonData} location={location}>
            <>
                <section className="hero is-medium">
                    <div className="hero-body">
                        <div
                            className="container"
                            style={{ maxWidth: '800px' }}
                        >
                            <div>
                                <Tabs>
                                    <TabList className="TabWrapperStyle">
                                        <Tab selectedClassName="selectedTabStyle">
                                            December 10
                                        </Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="vertiacl-timeline">
                                            <VerticalTimeline animate={false}>
                                                {airTable.map((item, i) => (
                                                    <VerticalTimelineElement
                                                        visible={true}
                                                        className="vertical-timeline-element--work"
                                                        date={
                                                            item.node.data
                                                                .Time_of_the_Event_GMT_4_
                                                        }
                                                        contentStyle={{
                                                            background: '#fff',
                                                            color: '#000',
                                                        }}
                                                        iconStyle={{
                                                            background:
                                                                '#ff2a45',
                                                            width: '20px',
                                                            height: '20px',
                                                        }}
                                                    >
                                                        <h3 className="vertical-timeline-element-title">
                                                            {
                                                                item.node.data
                                                                    .Speakers
                                                            }
                                                        </h3>

                                                        <p className="vertical-timeline-element-description">
                                                            {
                                                                item.node.data
                                                                    .Session_Type
                                                            }
                                                        </p>
                                                    </VerticalTimelineElement>
                                                ))}
                                            </VerticalTimeline>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

eventAgenda.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default eventAgenda

export const pageQuery = graphql`
    query eventAgendaQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        allAirtable(
            sort: { order: ASC, fields: data___Time_of_the_Event_GMT_4_ }
            filter: {
                data: {
                    Speakers: { ne: "TBA" }
                    Time_of_the_Event_GMT_4_: { ne: "null" }
                }
            }
        ) {
            edges {
                node {
                    data {
                        Time_of_the_Event_GMT_4_(
                            formatString: "yyyy-MM-DD HH:mm"
                        )
                        Speakers
                        Session_Type
                    }
                }
            }
        }
        markdownRemark {
            html
            frontmatter {
                id
                title
            }
            fields {
                slug
            }
        }
    }
`
